import React, { useState, useEffect } from 'react';
import axios from "axios";
import Image from "next/image";
import Link from 'next/link';
import Slider from "react-slick";

export default function Instagallery() {
    const [isMobile, setIsMobile] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [feeds, setFeeds] = useState([]);

    const settings = {
        dots: false,
        infinite: true,
        arrows: false,
        speed: 500,
        // autoplay: true,
        autoplaySpeed: 3000,
        slidesToShow: 5,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    centerMode: true,
                    centerPadding: "40px",
                },
            },
            {
                breakpoint: 480,
                settings: {
                    // className: "center",
                    // infinite: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    centerPadding: "40px",
                },
            },
        ],
    };

    const filtered_feed = feeds.filter(item => {
        return item.media_type != "VIDEO";
    });

    useEffect(() => {
        const fetchFeeds = () => {
            axios
                .get('https://graph.instagram.com/me/media?fields=id,media_type,media_url,permalink,caption&limit=15&access_token=IGQWROc2dZAYjRQSFU0cDBnUW1hWjBpd1lWOTlQdFJWZAVRRWVJPMkJlNkNXbklKZAmZARc1plc1hzZAFFJYWhGSk1QWUtqZAkV3Nm9uSFk0dEtSZAGptZA1VqWmVISzFXMHZAqUWh0NERVRnhndFV2Wl9FWmtuem1jUmNFcEEZD')
                .then((res) => {
                    setFeeds(res.data.data);
                    setIsLoading(false);
                })
                .catch((err) => {
                    console.log(err);
                });
        };
        fetchFeeds();
    }, []);

    useEffect(() => {
        setIsMobile(window.innerWidth <= 768);

        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // if (isLoading) {
    //     return <div>Loading...</div>;
    // }
    return (
        <>
            {filtered_feed && filtered_feed.length > 0 && (
                <section className="insta_gallery_section common_padding pb-0">
                    <div className='common_title text-center insta_username'>
                        <Link href="https://www.instagram.com/adventureresorts" target="_blank">@adventureresorts</Link><br />
                        <Image src="/icons/line.svg" width={120} height={2} alt='Line Icon'></Image>
                    </div>
                    {isLoading ? (
                        <div className="text-center">
                            <p>Loading ...</p>
                        </div>
                    ) : (
                        <>
                            {isMobile ? (
                                <Slider {...settings} className="insta_gallery_slider">
                                    {filtered_feed && filtered_feed.slice(0, 10).map((feed, index) => (
                                        <div className="instaimg" key={index}>
                                            <Image width={381} height={400} id={feed.id} src={feed.media_url} alt={feed.caption}></Image>
                                            <Link href={feed.permalink} target="_blank" className="middle">
                                                <Image src="/icons/instagram-icon.svg" height={30} width={30} alt='InstaIcon'></Image>
                                            </Link>
                                        </div>
                                    ))}
                                </Slider>
                            ) :
                                <div className='container'>
                                    <div className='insta_gallery_desktop_main'>
                                        <div className='insta_gallery_desktop_left'>
                                            {filtered_feed && filtered_feed.slice(0, 1).map((feed, index) => (
                                                <div className="instaimg" key={index}>
                                                    <Image width={381} height={400} id={feed.id} src={feed.media_url} alt={feed.caption}></Image>
                                                    <Link href={feed.permalink} target="_blank" className="middle">
                                                        <Image src="/icons/instagram-icon.svg" height={30} width={30} alt='InstaIcon'></Image>
                                                    </Link>
                                                </div>
                                            ))}
                                        </div>
                                        {/* </div> */}
                                        <div className='insta_gallery_desktop_right'>
                                            {filtered_feed && filtered_feed.slice(1, 5).map((feed, index) => (
                                                <div className='insta_gallery_desktop_right_child' key={index}>
                                                    <div className="instaimg" >
                                                        <Image width={381} height={400} id={feed.id} src={feed.media_url} alt={feed.caption}></Image>
                                                        <Link href={feed.permalink} target="_blank" className="middle">
                                                            <Image src="/icons/instagram-icon.svg" height={30} width={30} alt='InstaIcon'></Image>
                                                        </Link>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            }
                        </>
                    )}
                </section>
            )}

        </>
    )
}