import React, { useRef, useState, useEffect } from 'react';
import ReactPlayer from 'react-player';

const AutoPlayAudio = () => {
  const playerRef = useRef(null);
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  const handlePlayerReady = () => {
    // When the player is ready, start playback
    playerRef.current.getInternalPlayer().play();
  };

  const handlePlayerError = (error) => {
    console.error('Error during playback:', error);
    // Handle playback error if needed
  };

  return (
    <>
      {isClient && (
        <div style={{ display: 'none' }}>
          <ReactPlayer
            ref={playerRef}
            url='/Audio/christmas.mp3'
            controls={true}
            playing={true} // Start playback
            onReady={handlePlayerReady}
            onError={handlePlayerError}
          />
        </div>
      )}
    </>
  );
};

export default AutoPlayAudio;
